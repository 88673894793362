import React from 'react';
import {Translation} from 'reservation-common-web';

interface ISubscriptionsProps {}

const Subscriptions: React.FC<ISubscriptionsProps> = ({}) => {
    const isLoading = false; // ToDo add isLoading from corresponding slice

    return (
        <div>
            <div className="header custom-header">
                <h2 className="title">
                    <Translation text="subscriptions_page.title" />
                </h2>
            </div>
        </div>
    );
};

export default Subscriptions;
