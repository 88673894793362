import React from 'react';
import {Translation, IRestaurantInput, IRestaurantConfigurationInput, IRoomInput} from 'reservation-common-web';
import RoomDetailsForm from './RoomDetailsForm';
import OpeningHoursForm from './OpeningHoursForm';
import ReservationDetailsForm from './ReservationDetailsForm';
import RoomDivisionForm from './RoomDivision';
import FormPresentationForm from './FormPresentation';
import FormTitleForm from './FormTitle';
import {useDispatch, useSelector} from 'react-redux';
import {createRestaurant} from '../../store/reducers/restaurantSlice';
import {isRestaurantFormValidSelector} from '../../store/selectors/restaurantSelector';

interface IReservationFormProps {}

const ReservationForm: React.FC<IReservationFormProps> = ({}) => {
    const isLoading = false; // ToDo add isLoading from corresponding slice
    const dispatch = useDispatch();
    const createRestaurantAction = () => {
        const room: IRoomInput = {
            id: null,
            name: null,
            default: true,
            maxCount: 50,
            maxCountPerReservation: 5,
            hourStart: '09:00',
            hourEnd: '17:00',
            step: 15,
            prepayRequired: false,
            prepayPerPerson: {
                amount: '1000',
                currency: 'USD',
            },
        };
        const restaurantConfigurationPayload: IRestaurantConfigurationInput = {
            privacyPolicy: 'Privacy Policy',
            openHours: {
                _0: {from: '09:00', to: '17:00'},
                _1: {from: '09:00', to: '18:00'},
                _2: {from: '09:00', to: '18:00'},
                _3: {from: '09:00', to: '18:00'},
                _4: {from: '09:00', to: '18:00'},
                _5: {from: '09:00', to: '18:00'},
                _6: {from: '10:00', to: '16:00'},
            },
            color: '#000000',
            background: 'www.example.com',
            avatar: 'www.example.com',
            title: 'Title',
            floorPlan: 'www.example.com',
            shortDescription: 'Short Description',
            initialDescription: 'Initial Description',
        };
        const restaurantPayload: IRestaurantInput = {
            name: 'Restaurant Name',
            address: 'Restaurant Address',
            email: 'restaurant_0@yopmail.com',
            phone: {
                country: 'PL',
                phone: '1234567',
            },
            slug: 'restaurant-slug',
            configuration: restaurantConfigurationPayload,
            rooms: [room],
        };
        console.log('restaurantPayload', restaurantPayload);
        dispatch(createRestaurant(restaurantPayload));
    };
    const isFormValid = useSelector(isRestaurantFormValidSelector);
    const isPremium = false; // ToDo add isPremium from corresponding slice
    return (
        <div>
            <div className="header">
                <h2 className="title">
                    <Translation text="form_page.form" />
                </h2>
            </div>
            <div className="content">
                <RoomDetailsForm />
                <OpeningHoursForm />
                {isPremium ? <RoomDivisionForm /> : <ReservationDetailsForm />}
                <FormPresentationForm />
                <FormTitleForm />
                <button className="btn btn-primary" type="submit" onClick={createRestaurantAction} disabled={!isFormValid}>
                    <Translation text="form_page.save" />
                </button>
            </div>
        </div>
    );
};

export default ReservationForm;
