import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator} from 'reservation-common-web';
import CustomCard from '../../CustomCard';
import formPresentationFormConfig from './formPresentationFormConfig';
import {restaurantFormAppearanceSelector} from '../../../store/selectors/restaurantSelector';
import {setFormAppearance} from '../../../store/reducers/restaurantSlice';

const FormPresentationForm: React.FC = () => {
    const formAppearance = useSelector(restaurantFormAppearanceSelector);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const initialValues = {
        color: formAppearance.color,
        background: formAppearance.background,
    };
    const saveFormPresentation = (values: any) => {
        console.log(values, 'values');
        const presentationPayload = {
            color: values.color,
            background: values.background,
        };
        dispatch(setFormAppearance(presentationPayload));
    };

    return (
        <CustomCard
            title="form_page.form_presentation.title"
            content={
                <div className="account-form-container">
                    <FormGenerator
                        config={{
                            fields: formPresentationFormConfig(t),
                            initialValues,
                            onSubmit: saveFormPresentation,
                            onChange: (values: any) => {
                                console.log(values, 'valuesonchange');
                            },
                            debouncedChangeTimeout: 1000,
                            formId: 'change-form-presentation-form',
                            formClassName: '',
                        }}
                        isFormValid={(valid: boolean) => dispatch(setFormAppearance(valid))}
                    />
                </div>
            }
        />
    );
};

export default FormPresentationForm;
