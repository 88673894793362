import {
    AlertType,
    DetailsSuccessActionsFunction,
    IRestaurantOutput,
    addAlert,
    closeModal,
    createRestaurantAPI,
    createOperationEpic,
    deleteRestaurantAPI,
    handleApiError,
    updateRestaurantAPI,
    authTokenSelector,
    IRestaurantInput,
} from 'reservation-common-web';
import {push} from 'react-router-redux';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {createRestaurant, deleteRestaurant, setError, setLoading, setRestaurant, updateRestaurant} from '../reducers/restaurantSlice';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {PayloadAction} from '@reduxjs/toolkit';
import {of} from 'rxjs';

const createRestaurantSuccessActions: DetailsSuccessActionsFunction<IRestaurantOutput> = (res: IRestaurantOutput) => {
    console.log('createRestaurantSuccessActions', res);
    return [
        addAlert({message: 'restaurants.restaurantOperation.restaurantCreated'}),
        setLoading(false),
        push('/panel'),
        setRestaurant(res),
    ];
};

const updateRestaurantSuccessActions: DetailsSuccessActionsFunction<IRestaurantOutput> = (restaurant: IRestaurantOutput) => {
    return [addAlert({message: 'restaurants.restaurantOperation.restaurantUpdated'}), setLoading(false), setRestaurant(restaurant)];
};

const deleteRestaurantSuccessActions: DetailsSuccessActionsFunction<null> = () => {
    return [addAlert({message: 'restaurants.restaurantOperation.restaurantDeleted'}), setLoading(false), closeModal(), push('/panel/')];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const createRestaurantEpic = createOperationEpic<IRestaurantOutput>(
    createRestaurantAPI,
    createRestaurantSuccessActions,
    errorActions,
    createRestaurant().type
);

const updateRestaurantEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(updateRestaurant().type),
        switchMap((action: PayloadAction<{id: string; restaurant: IRestaurantInput}>) => {
            const authToken = authTokenSelector(state$.value);
            return updateRestaurantAPI(authToken, {id: action.payload.id, restaurant: action.payload.restaurant}).pipe(
                mergeMap((res: IRestaurantOutput) => of(...updateRestaurantSuccessActions(res))),
                catchError((error) => {
                    return errorActions(error);
                })
            );
        })
    );

const deleteRestaurantEpic = createOperationEpic<null>(
    deleteRestaurantAPI,
    deleteRestaurantSuccessActions,
    errorActions,
    deleteRestaurant().type
);

const restaurantEpic = combineEpics(createRestaurantEpic, createRestaurantEpic, updateRestaurantEpic, deleteRestaurantEpic);

export default restaurantEpic;
