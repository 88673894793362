import React from 'react';
import {Translation} from 'reservation-common-web';

interface ProfileItemProps {
    label: string;
    children: React.ReactNode;
}

const ProfileItem: React.FC<ProfileItemProps> = ({label, children}) => {
    return (
        <div className="profile-item">
            <div className="label-container">
                <p className="label">
                    <Translation text={label} />
                </p>
            </div>
            <div className="value-container">{children}</div>
        </div>
    );
};

export default ProfileItem;
