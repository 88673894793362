import React from 'react';
import {Translation} from 'reservation-common-web';

interface CustomCardProps {
    title: string;
    content: React.ReactNode;
}

const CustomCard: React.FC<CustomCardProps> = ({title, content}) => {
    return (
        <div className="custom-card">
            <div className="card-title-container">
                <p className="card-title">
                    <Translation text={title} />
                </p>
            </div>
            <div className="card-content-container">{content}</div>
        </div>
    );
};

export default CustomCard;
