import React from 'react';
import {Route} from 'react-router';
import PanelHost from '../components/PanelHost';
import {Navigate} from 'react-router-dom';
import {AuthPanel, NotFound, UserRole} from 'reservation-common-web';

const routes = [
    <Route key="auth" path="/auth/*" element={<AuthPanel envData={process.env} userRole={UserRole.USER} />} />,
    <Route key="default-route" path="/" element={<Navigate to="/panel/reservations" />} />,
    <Route path="/panel/*" key="panel" element={<PanelHost />} />,
    <Route path="*" key="not-found" element={<NotFound />} />,
];
export default routes;
