import React, {useEffect, useState} from 'react';
import './../../assets/styles/admin-panel.scss';
import SearchPanel from '../SearchPanel';
import Table from '../Table';
import {Translation} from 'reservation-common-web';
import {useDispatch} from 'react-redux';

// Mock data for the table
const initialData = [
    {
        contact: 'Krzysio',
        room: 'Main',
        numberOfPeople: 8,
        time: '19:00',
        prepayment: '160 PLN',
        message:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
        contact: 'Krzysio',
        room: 'Main',
        numberOfPeople: 10,
        time: '20:30',
        prepayment: '200 PLN',
        message: 'Donec dafuktry: Solve Studio Sp. z o.o. Zuga 29',
    },
    {
        contact: 'Krzysio',
        room: 'Vintage',
        numberOfPeople: 10,
        time: '21:00',
        prepayment: '200 PLN',
        message: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
];

const Reservations: React.FC = () => {
    const [data, setData] = useState(initialData);
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(getReservationsList());
    }, []);

    const handleSearch = (query: string) => {
        const filteredData = initialData.filter(
            (item) =>
                item.contact.toLowerCase().includes(query.toLowerCase()) ||
                item.room.toLowerCase().includes(query.toLowerCase()) ||
                item.message.toLowerCase().includes(query.toLowerCase())
        );
        setData(filteredData);
    };

    const downloadReport = () => {
        console.log('Download report');
    };

    return (
        <>
            <div className="header">
                <h2 className="title">
                    <Translation text="reservations_page.title" />
                </h2>
                <div className="actions-container">
                    <button onClick={downloadReport} className="btn-back">
                        <span className="btn-icon icon-print" />
                        <Translation text={'reservations_page.print'} />
                    </button>
                    <SearchPanel onSearch={handleSearch} />
                </div>
            </div>
            <Table data={data} />
        </>
    );
};

export default Reservations;
