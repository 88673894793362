import {
    AlertType,
    IModelApiResponseViewObject,
    IReservationOutput,
    ListSuccessActionsFunction,
    addAlert,
    createFetchListEpic,
    flattenObj,
    getReservationListAPI,
    handleApiError,
} from 'reservation-common-web';
import {combineEpics} from 'redux-observable';
import {setError, setLoading, getReservationsList, setReservationsList, setMetadata} from '../reducers/reservationListingSlice';

const reservationsListingSuccessActions: ListSuccessActionsFunction<IReservationOutput> = (
    jobsArray: IReservationOutput[],
    metadata: IModelApiResponseViewObject | null
) => {
    return [setReservationsList(jobsArray), setMetadata({metadata: metadata}), setLoading(false)];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const getReservationListEpic = createFetchListEpic<IReservationOutput>(
    getReservationListAPI,
    reservationsListingSuccessActions,
    errorActions,
    getReservationsList().type
    // (state: RootState) => getHistoricalJobsParameters(state)
);

const reservationListingEpic = combineEpics(getReservationListEpic);

export default reservationListingEpic;
