import React from 'react';
import {Translation} from 'reservation-common-web';
import ProfileCard from './ProfileCard';
import ChangePasswordCard from './ChangePasswordCard';
import SettingsCard from './SettingsCard';
import ProfileDeleteCard from './ProfileDeleteCard';

const Account: React.FC = () => {
    return (
        <>
            <div className="header">
                <h2 className="title">
                    <Translation text="account_page.title" />
                </h2>
            </div>
            <div className="account-details">
                <ProfileCard />
                <ChangePasswordCard />
                <SettingsCard />
                <ProfileDeleteCard />
            </div>
        </>
    );
};

export default Account;
