import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes, FormikRegexSettings} from 'reservation-common-web';

const roomDetailsFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'name',
            label: t('form_page.room_details.form.labels.name'),
            isRequired: true,
            type: FormikFieldTypes.TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'address',
            label: t('form_page.room_details.form.labels.address'),
            isRequired: true,
            type: FormikFieldTypes.TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'email',
            label: t('form_page.room_details.form.labels.email'),
            isRequired: true,
            type: FormikFieldTypes.EMAIL,
            validation: Yup.string().email(t('validation.invalidEmail')).required(t('validation.required')),
            className: '',
        },
        {
            name: 'phone',
            label: t('form_page.room_details.form.labels.phone'),
            isRequired: true,
            type: FormikFieldTypes.NUMBER,
            validation: Yup.string().matches(FormikRegexSettings.PHONE, 'validation.invalidPhoneNumber').required(t('validation.required')),
            className: '',
        },
        {
            name: 'privacy_policy',
            label: t('form_page.room_details.form.labels.privacy_policy'),
            type: FormikFieldTypes.RICH_TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
    ];
};

export default roomDetailsFormConfig;
