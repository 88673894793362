import React, {useState} from 'react';
import CustomCard from '../../CustomCard';
import {Translation} from 'reservation-common-web';
import DeleteProfileModal from './DeleteProfileModal';

const ProfileDeleteCard: React.FC = () => {
    const [isModalShown, setIsModalShown] = useState(false);

    const toggleModal = () => setIsModalShown((isShown) => !isShown);

    const handleConfirm = () => {
        console.log('Confirm delete');
    };

    return (
        <>
            <CustomCard
                title="account_page.delete_account.title"
                content={
                    <div className="detele-account-section">
                        <p className="description">
                            <Translation text="account_page.delete_account.description" />
                        </p>
                        <button onClick={() => toggleModal()} className="btn-delete">
                            <Translation text="account_page.delete_account.btn_delete" />
                        </button>
                    </div>
                }
            />
            {isModalShown ? (
                <DeleteProfileModal isModalShown={isModalShown} toggleModal={toggleModal} handleConfirm={handleConfirm} />
            ) : null}
        </>
    );
};

export default ProfileDeleteCard;
