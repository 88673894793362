import {combineEpics} from 'redux-observable';
import reservationListingEpic from './reservationListingEpic';
import {authEpic, accountEpic, changePasswordEpic, loginEpic, reauthenticateEpic, renewAuthTokenEpic} from 'reservation-common-web';
import restaurantEpic from './restaurantEpic';

export const rootEpic = combineEpics(
    loginEpic,
    renewAuthTokenEpic,
    authEpic,
    reauthenticateEpic,
    changePasswordEpic,
    reservationListingEpic,
    restaurantEpic,
    accountEpic
);
