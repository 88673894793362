import {FormikFieldConfig, FormikFieldTypes} from 'reservation-common-web';
import * as Yup from 'yup';

const formPresentationFormConfig = (t: any): FormikFieldConfig[] => {
    return [
        {
            name: 'avatar',
            label: t('form_page.form_title.form.labels.avatar'),
            type: FormikFieldTypes.FILE,
            validation: Yup.string().required(t('validation.required')),
            className: '',
            fileUploadRequirements: [
                t('file_upload.file_extensions', {extensions: 'jpg, jpeg, png'}),
                t('file_upload.file_size', {size: '1Mb'}),
                t('file_upload.file_resolution', {resolution: '100 x 100'}),
            ],
        },
        {
            name: 'title',
            label: t('form_page.form_title.form.labels.title'),
            type: FormikFieldTypes.TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'description',
            label: t('form_page.form_title.form.labels.description'),
            type: FormikFieldTypes.RICH_TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'initial_information',
            label: t('form_page.form_title.form.labels.initial_information'),
            type: FormikFieldTypes.RICH_TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
    ];
};

export default formPresentationFormConfig;
