import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes} from 'reservation-common-web';

const formPresentationFormConfig = (t: any): FormikFieldConfig[] => {
    return [
        {
            name: 'color',
            label: t('form_page.form_presentation.form.labels.color'),
            type: FormikFieldTypes.COLORPICKER,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'background',
            label: t('form_page.form_presentation.form.labels.background_image'),
            isRequired: true,
            type: FormikFieldTypes.FILE,
            validation: Yup.string().required(t('validation.required')),
            className: 'avatar-upload',
            fileUploadRequirements: [
                t('file_upload.file_extensions', {extensions: 'jpg, jpeg, png'}),
                t('file_upload.file_size', {size: '4Mb'}),
                t('file_upload.file_resolution', {resolution: '1920x1080'}),
            ],
        },
    ];
};

export default formPresentationFormConfig;
