import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IRestaurantState} from '../reducers/restaurantSlice';

export const selectRestaurantSlice = (state: RootState) => {
    return state.restaurant;
};

export const restaurantDetailsSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => {
    return {
        name: state.name,
        address: state.address,
        email: state.email,
        phone: state.phone,
        slug: state.slug,
        privacyPolicy: state.configuration?.privacyPolicy,
    };
});

export const openingHoursSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.configuration?.openHours);

export const restaurantRoomsSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.rooms);
export const restaurantRoomDivisionSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => '' // TODO PT: zglosic brak roomLayout na serwerze
);
export const restaurantFormAppearanceSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => {
    return {
        color: state.configuration?.color,
        background: state.configuration?.background,
    };
});

export const restaurantHeadingSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => {
    return {
        avatar: state.configuration?.avatar,
        title: state.configuration?.title,
        shortDescription: state.configuration?.shortDescription,
        initialDescription: state.configuration?.initialDescription,
    };
});

export const isRestaurantDetailsValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isRestaurantDetailsValid
);
export const isRestaurantHeadingValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isRestaurantHeadingValid
);
export const isRestaurantRoomsValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isRestaurantRoomsValid
);
export const isRestaurantAppearanceValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isFormAppearanceValid
);
export const isRestaurantFormValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) =>
        state.isRestaurantDetailsValid && state.isRestaurantHeadingValid && state.isRestaurantRoomsValid && state.isFormAppearanceValid
);

export const isRestaurantLoadingSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.isLoading);

export const restaurantListingInitializedSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isInitialized
);
export const restaurantListingErrorSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.error);
