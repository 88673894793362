import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes, FormikRegexSettings} from 'reservation-common-web';

const changePasswordFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'oldPassword',
            label: t('account_page.password_change.form.labels.old_password'),
            type: FormikFieldTypes.PASSWORD,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'newPassword',
            label: t('account_page.password_change.form.labels.new_password'),
            type: FormikFieldTypes.PASSWORD,
            validation: Yup.string()
                .required(t('validation.required'))
                .matches(FormikRegexSettings.PASSWORD, t('validation.passwordPattern')),
            className: '',
        },
        {
            name: 'repeatPassword',
            label: t('account_page.password_change.form.labels.repeat_password'),
            type: FormikFieldTypes.PASSWORD,
            validation: Yup.string()
                .required(t('validation.required'))
                .matches(FormikRegexSettings.PASSWORD, t('validation.passwordPattern'))
                .oneOf([Yup.ref('newPassword')], t('validation.passwordsMustMatch')),
            className: '',
        },
    ];
};

export default changePasswordFormConfig;
