import React from 'react';
import '../../assets/styles/navbar.scss';
import {Translation} from 'reservation-common-web';
import {Link} from 'react-router-dom';

const Navbar: React.FC = () => {
    return (
        <nav className="navbar">
            <div className="app-details">
                <img className="logo" src={require('../../assets/images/app_logo.png')} alt="Logo" />
                <div className="app-name">
                    <p className="title">
                        <Translation text="navbar.app_title" />
                    </p>
                    <p className="subtitle">
                        <Translation text="navbar.app_subtitle" />
                    </p>
                </div>
            </div>
            <ul>
                <li className="navbar-item subscriptions-link">
                    <Link to="/panel/subscriptions">
                        <Translation text="navbar.go_premium" />
                    </Link>
                </li>
                <span className="divider" />
                <li className="navbar-item reservations-link">
                    <Link to="/panel/reservations">
                        <Translation text="navbar.reservations" />
                    </Link>
                </li>
                <li className="navbar-item form-link">
                    <Link to="/panel/reservation-form">
                        <Translation text="navbar.form" />
                    </Link>
                </li>
                <span className="divider" />
                <li className="navbar-item account-link">
                    <Link to="/panel/account" />
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
