import React, {useEffect} from 'react';
import CustomCard from '../../CustomCard';
import ProfileItem from './ProfileItem';
import {useSelector} from 'react-redux';
import {accountUserNameSelector, Translation} from 'reservation-common-web';

const ProfileTab: React.FC = () => {
    const username: string | undefined = useSelector(accountUserNameSelector);

    useEffect(() => {
        console.log('username', username);
    }, []);

    const renderSubscriptionDetails = () => {
        const isPremium = false;
        return isPremium ? (
            <p>Premium</p>
        ) : (
            <>
                <p className="value plan-value">Free</p>
                <button className="btn-premium">
                    <span className="icon-rocket" />
                    <Translation text="account_page.profile.change_premium" />
                </button>
            </>
        );
    };

    const renderStripeDetails = () => {
        return (
            <>
                <button className="btn-premium btn-account-plan">
                    <span className="icon-rocket" />
                    <Translation text="account_page.profile.onboarding" />
                </button>
                <button className="btn-back">
                    <span className="btn-icon plus-icon" />
                    <Translation text="account_page.profile.stripe_url" />
                </button>
            </>
        );
    };

    return (
        <CustomCard
            title="account_page.profile.title"
            content={
                <div className="profile-details">
                    <ProfileItem label="account_page.profile.email">
                        <p className="value">{username}</p>
                    </ProfileItem>

                    <ProfileItem label="account_page.profile.plan">{renderSubscriptionDetails()}</ProfileItem>

                    <ProfileItem label="account_page.profile.plan_expiration">
                        <p className="value">21.09.2024</p>
                    </ProfileItem>

                    <ProfileItem label="account_page.profile.stripe">{renderStripeDetails()}</ProfileItem>
                </div>
            }
        />
    );
};

export default ProfileTab;
