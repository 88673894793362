import React from 'react';
import {BasicModal, Translation} from 'reservation-common-web';

interface DeleteAccountDialogProps {
    isModalShown: boolean;
    toggleModal: () => void;
    handleConfirm: () => void;
}

const DeleteProfileModal: React.FC<DeleteAccountDialogProps> = ({isModalShown, toggleModal, handleConfirm}) => {
    return (
        <BasicModal isModalShown={isModalShown} closeModal={toggleModal}>
            <BasicModal.Header>
                <h2>
                    <Translation text="account_page.delete_account.delete_modal.title" />
                </h2>
            </BasicModal.Header>
            <BasicModal.Body>
                <Translation text="account_page.delete_account.delete_modal.subtitle" />
                <Translation text="account_page.delete_account.delete_modal.warning" />
            </BasicModal.Body>
            <BasicModal.Footer>
                <button onClick={toggleModal} className="btn-primary-outline">
                    <Translation text="account_page.delete_account.delete_modal.buttons.close_modal" />
                </button>
                <button onClick={handleConfirm} className="btn-primary">
                    <Translation text="account_page.delete_account.delete_modal.buttons.delete_account" />
                </button>
            </BasicModal.Footer>
        </BasicModal>
    );
};

export default DeleteProfileModal;
