import React, {Suspense} from 'react';
import {PrivateRoute, Toast, UserRole, Loader, LoaderType, NotFound} from 'reservation-common-web';
import Reservations from '../Reservations';
import Navbar from '../Navbar';
import {Routes} from 'react-router-dom';
import {Route} from 'react-router';
import Account from '../Account';
import Subscriptions from '../Subscriptions';
import ReservationForm from '../ReservationForm';

interface IPanelHostProps {}

const PanelHost: React.FC<IPanelHostProps> = ({}) => {
    const isLoading = false; // ToDo add isLoading from corresponding slice

    return (
        <PrivateRoute userRole={UserRole.USER}>
            <main className="admin-panel">
                <Navbar />
                <section className="main-container">
                    <Suspense fallback={<Loader showLoader={isLoading} type={LoaderType.Global} />}>
                        <Routes>
                            <Route path="reservations/" element={<Reservations />} key="reservations" />
                            <Route path="reservation-form/*" element={<ReservationForm />} key="reservation-form" />
                            <Route path="subscriptions/" element={<Subscriptions />} key="subscriptions" />
                            <Route path="account/" element={<Account />} key="account" />
                            <Route path="*" key="not-found" element={<NotFound />} />,
                        </Routes>
                    </Suspense>
                </section>
                <Toast />
                <Loader showLoader={isLoading} type={LoaderType.Global} />
            </main>
        </PrivateRoute>
    );
};

export default PanelHost;
