import {Field, Form, Formik} from 'formik';
import React, {useState} from 'react';
import '../../assets/styles/search-panel.scss';
import * as Yup from 'yup';
import {Translation} from 'reservation-common-web';

interface SearchPanelProps {
    onSearch: (query: string) => void;
}

const SearchPanel: React.FC<SearchPanelProps> = ({onSearch}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = () => {
        onSearch(searchTerm);
    };

    return (
        <div className="search-panel">
            <Formik
                initialValues={{date: '', people: '', time: '', room: ''}}
                validationSchema={Yup.object({
                    guestName: Yup.string().required('Required'),
                    phone: Yup.number().required('Required').min(1, 'Must be at least 1'),
                    email: Yup.string().required('Required'),
                    message: Yup.string().required('Required'),
                    termsAndConditions: Yup.string().required('Required'),
                })}
                onSubmit={(values) => {
                    // Handle form submission and proceed to next step
                    console.log('in formik on submit');
                }}>
                <Form className="search-form">
                    <div className="form-control">
                        <Field name="reservation" placeholder="" />
                        <label>
                            <Translation text={'reservations_page.find_reservation'} />
                        </label>
                    </div>
                    <div className="form-control">
                        <Field name="room" placeholder="" />
                        <label>
                            <Translation text={'reservations_page.room'} />
                        </label>
                    </div>
                    <div className="form-control">
                        <Field name="date" placeholder="" />
                        <label>
                            <Translation text={'reservations_page.date'} />
                        </label>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

export default SearchPanel;
