import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator} from 'reservation-common-web';
import CustomCard from '../../CustomCard';
import roomDetailsFormConfig from './roomDetailsFormConfig';
import {restaurantDetailsSelector} from '../../../store/selectors/restaurantSelector';
import {ISetRestaurantDetails, setRestaurantDetails, setRestaurantDetailsValid} from '../../../store/reducers/restaurantSlice';

const RoomDetailsForm: React.FC = () => {
    const roomDetails = useSelector(restaurantDetailsSelector);
    const dispatch = useDispatch();
    const initialValues = {
        name: roomDetails.name || '',
        address: roomDetails.address || '',
        email: roomDetails.email || '',
        phone: roomDetails.phone?.phone || '',
        privacy_policy: roomDetails.privacyPolicy || '',
    };
    const {t} = useTranslation();
    const saveRoomDetails = (values: any) => {
        console.log(values, 'change on debounce');
        const roomDetailsPayload: ISetRestaurantDetails = {
            name: values.name,
            address: values.address,
            email: values.email,
            phone: {
                country: '48',
                phone: values.phone,
            },
            slug: roomDetails.slug ? roomDetails.slug : '',
            privacyPolicy: values.privacy_policy,
        };
        dispatch(setRestaurantDetails(roomDetailsPayload));
    };

    return (
        <CustomCard
            title="form_page.room_details.title"
            content={
                <div className="account-form-container">
                    <FormGenerator
                        config={{
                            fields: roomDetailsFormConfig(t),
                            initialValues,
                            onSubmit: saveRoomDetails,
                            debouncedChangeTimeout: 1000,
                            formId: 'change-room-details-form',
                            formClassName: '',
                        }}
                        isFormValid={(valid: boolean) => dispatch(setRestaurantDetailsValid(valid))}
                    />
                </div>
            }
        />
    );
};

export default RoomDetailsForm;
