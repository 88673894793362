import React from 'react';
import '../../assets/styles/table.scss';

interface Reservation {
    contact: string;
    room: string;
    numberOfPeople: number;
    time: string;
    prepayment: string;
    message: string;
}

interface TableProps {
    data: Reservation[];
}

const Table: React.FC<TableProps> = ({data}) => {
    return (
        <table className="reservations-table">
            <thead>
                <tr>
                    <th>Contact</th>
                    <th>Room</th>
                    <th>Number of People</th>
                    <th>Time</th>
                    <th>Prepayment</th>
                    <th>Message</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        <td>{item.contact}</td>
                        <td>{item.room}</td>
                        <td>{item.numberOfPeople}</td>
                        <td>{item.time}</td>
                        <td>{item.prepayment}</td>
                        <td>{item.message}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Table;
